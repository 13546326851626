// Required for bootstrap mixins
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';
@import '../../styles/base/settings';

$closeTransition: all 300ms ease-in-out;
$topRowHeight: 80px;
$sidebarWidth: 250px;
$sidebarWidthClosed: 40px;
$crumbBarHeight: 50px;

.ld-layout-container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: $topRowHeight auto;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;

  .ld-company-logo,
  .ld-company-logo img,
  .header,
  .main-menu,
  .ld-layout-main > main {
    transition: $closeTransition;
  }

  &.closed {
    .ld-company-logo {
      position: absolute;
      img {
        opacity: 0;
      }
      width: $sidebarWidthClosed;
    }
    .header {
      grid-column: 1 / 3;
    }
    .main-menu {
      width: $sidebarWidthClosed;
    }
    .ld-layout-main > main {
      width: calc(100vw - #{$sidebarWidthClosed});
    }
  }

  .ld-layout-main {
    display: grid;
    grid-template-rows: $crumbBarHeight auto;
    background-color: #fcfcfc;
    z-index: 1;

    > main {
      overflow: auto;
      width: calc(100vw - #{$sidebarWidth});
      height: calc(100vh - #{$crumbBarHeight + $topRowHeight});
      transition: $closeTransition;
    }
  }
}

// Do not show logo at all on mobile screens, even when sidebar is open
@include media-breakpoint-down(xs) {
  .ld-layout-container {
    > .ld-company-logo {
      display: none;
    }

    > .header {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
}
