
.plan-details-header {
  font-size: 36px;
  font-weight: 500;
}

.plan-detail-section{
  .plan-detail-search-input{
    font-size: 16px;
    margin-bottom: 12px;
  }
}

.admin-plan-list{
  flex:1;
}

.users-with-plan-list-item{
  padding: 12px;
  &:nth-child(odd){
    background:$medium-grey;
  }
}

.users-with-plan-list-item-title{
  font-size: 24px;
  font-weight: 700;
}

.assgin-plan-page{
  flex:1;
}

.plan-detail-item {
  border-bottom:1px solid black;
}

.plan-detail-item-title{
  font-size: 16px;
  font-weight: 500;
  margin: 12px 0;
}

.plan-detail-sub-item {
  font-size: 12px;
  font-weight: 200;
  border-top: 1px solid black;
  background-color: #E5E5E5;
  margin: 0;
  padding: 6px 0;
  padding-left: 12px;
}

.plan-input-list{
  margin-top:12px;
}

.input-list-title {
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 700;
}

.input-list-title::after {
  content: "";
  position: relative;
  top: 10px;
  display: block;
  background-color: #26D07C;
  height: 8px;
  width: 100px;
  border-radius: 10px;
}

.plan-input-drop-down-space{
  flex:1;
  margin-left:6px;
}

.plan-input-row-title {
  margin:0;
}

.plan-input-row {
  padding: 12px;
  &:nth-child(odd){
    background:$medium-grey;
  }
}




.plan-input-list-green{
  margin-top:12px;

  .plan-input-row {
    padding: 12px;
    &:nth-child(odd){
      background:#8edd654a;
    }
  }
}
