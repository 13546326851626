$pane-header-height: 64px;

.slide-pane {
  display: flex;
  flex-direction: column;
  background: #fff;
  min-width: 100px;
  height: 100%;
  box-shadow: 0 8px 8px rgba(0,0,0,0.5);

  transition: transform .5s;
  will-change: transform;

  &:focus {
    outline-style: none;
  }

  &_from_right {
    margin-left: auto;
    transform: translateX(100%);

    &.ReactModal__Content--after-open {
      transform: translateX(0%);
    }

    &.ReactModal__Content--before-close {
      transform: translateX(100%);
    }
  }

  &_from_left {
    margin-right: auto;
    transform: translateX(-100%);

    &.ReactModal__Content--after-open {
      transform: translateX(0%);
    }

    &.ReactModal__Content--before-close {
      transform: translateX(-100%);
    }
  }

  &_from_bottom {
    height: 90vh;
    margin-top: 10vh;
    transform: translateY(100%);
    &.ReactModal__Content--after-open {
      transform: translateY(0%);
    }
    &.ReactModal__Content--before-close {
      transform: translateY(100%);
    }
  }

  &__overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0);
    z-index: 1000;
  }

  &__overlay.ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, .3);
    transition: background-color .5s;
  }

  &__overlay.ReactModal__Overlay--before-close {
    background-color: rgba(0, 0, 0, 0);
  }

  &__header {
    display: flex;
    flex: 0 0 $pane-header-height;
    align-items: center;
    background: #ebebeb;
    height: $pane-header-height;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding: 0px 10px;
  }

  &__title-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 32px;
    min-width: 0;   // text ellipsis fix in child
  }

  & &__title {
    font-size: 18px;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    padding: 0;
  }

  &__close {
    margin-left: 24px;
    padding: 16px;
    opacity: .7;
    cursor: pointer;

    svg {
      width: 12px;
      padding: 0;
    }
  }

  &__content {
    position: relative;
    overflow-y: auto;
    padding: 24px 32px;
    flex: 1 1 auto;
  }

  &__subtitle {
    font-size: 12px;
    margin-top: 2px;
  }
}
