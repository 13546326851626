.background {
  background: $off-white;
  background-size: cover;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
}

.login-box {
  background: fade-out(white, $amount: 0.15);
  border-radius: 3px;
  border: 1px solid #ddd;
  height: fit-content;
  margin-top: 125px;
  padding: $l-size $m-size;
  text-align: center;
  width: 35rem;
}

.box-layout__title {
  margin: 0 0 $m-size 0;
  line-height: 1;
}

.login {
  background: $off-white;
}

.login__copyright {
  color: $light-grey;
  font-size: 13px;
  text-align: center;
}

.login__logo {
  max-width: 150px;
  margin-bottom: 30px;
}

.leap__login__logo {
  max-width: 100px;
  margin-bottom: 8px;
}

.forgot__password {
  background-color: transparent;
  border: 0;
  color: gray;
}
