#spreedly-number iframe {
  height: 34px;
  border: 1px solid rgb(206, 212, 218); border-radius: 3px;
}

#spreedly-cvv iframe {
  height: 34px;
  width: 100%;
  border: 1px solid rgb(206, 212, 218);
  border-radius: 3px;
}