.result-dropable {
  & .leap-select__menu {
    position: relative;
    z-index: 9999999;
  }

  & .lpstrp-select {
    font-size: 12px;
  }
  & input.form-control {
    height: 38px;
  }
  & .lpstrp-select__input {
    input {
      height: unset;
    }
  }
  & .lpstrp-select__value-container {
    padding-bottom: 0px;
    padding-top: 0px;
    div {
      padding-bottom: 0px;
      padding-top: 0px;
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }

  & .lpstrp-select__indicator {
    padding-bottom: 0px;
    padding-top: 0px;
  }

  & .add-detail-block {
    border: 1px solid black;
    border-radius: 4;
    margin-bottom: 8px;
    background-color: white;
  }
}
