.company-logo-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  > img {
    height: 15rem;
    margin: auto;
    object-fit: contain;
    text-align: center;
    margin-bottom: 1rem;
  }
}