

.corporate-admin-dashboard-button{
  height:286px;
  width:205px;
  color:grey;
  box-shadow: 0px 3px 6px;
  margin-bottom: 24px;
  padding: 0 12px;
  background-color: white;
}


.corporate-admin-dashboard-button-title{
  font-size: 28px;
  font-weight: 700;
  color:black;
}

.corporate-admin-dashboard-button-img{
  height:150px;
}