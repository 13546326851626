.table {
  margin-bottom: 0 !important;
  border: 1px solid #ddd;
  border-radius: 5px;
  border-collapse: collapse !important;
  border-radius: 1px;
  width: 100%;
}

.table-header__cell {
  padding: 8px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 50%;
  align-content: center;
  border: 1px solid transparent;
  border-width: 1px;
  border-color: lightgray;
}

.table-row {
  width: 50%;
  border-width: 1px;
  border-color: lightgray;
  text-align: center;
}

.table-row__cell {
  border: 1px solid transparent;
  border-radius: 4px;
  border-color: #ddd;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  padding: 8px;
  font-size: 12px;
}

.table-row__cell__input {
  @extend .table-row__cell;
  width: 100%;
}

.td--icon {
  &:hover {
    background: #d5d8dc;
  }
}

.categories-table {
  margin-bottom: 100px !important;
  table-layout: fixed;
  border: 1px solid #ddd;
  border-radius: 5px;
  border-collapse: collapse !important;
  border-radius: 1px;
  width: 100%;
}
