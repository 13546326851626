.status-check-section {
  font-weight: 500;
  font-size: 16px;
}

.status-check-alert {
  text-align: left;
  i {
    margin: 5px;
  }
  font-weight: 800;
  font-size: 16px;
  white-space: nowrap;
}

.type-warning {
  background-color: #d89954;
}

.type-danger {
  background-color: #f40000;
}

.status-check-message {
  text-align: left;
  margin: 0;
  font-weight: 500;
  font-size: 12px;
}

.status-check-table-headers {
  font-weight: 800;
  font-size: 16px;
}

.onboarding-progress-result {
  font-weight: 900;
  font-size: 36px;
}

.onboarding-progress-total {
  font-weight: 500;
  font-size: 12px;
}
.onboarding-sort-arrow {
  margin: 5px;
}

.onboarding-sort-arrow-asc {
  transform: rotate(180deg);
  margin: 5px;
}

.onboarding-status-page {
  .table {
    .responsive-table-header {
      background-color: #0d2e56;
      color: white;
    }
  }
}

.progress-row {
  .onboarding-progress-bar {
    margin-top: 30px;
    height: 20px;
    .bp3-progress-meter {
      background: linear-gradient(
        90deg,
        rgba(158, 225, 107, 1) 0%,
        rgba(80, 213, 131, 1) 100%
      );
    }
  }
}
