.form {
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: $m-size;
  }
}

input::placeholder {
  color: #aaa;
}

input:disabled {
  color: #ddd;
}

.input-pair {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 1rem;
  & > .input-pair_title {
    font-weight: bold;
    text-align: right;
    line-height: 2.5rem;
  }
  & > * {
    padding: auto;
    width: 50%;
    line-height: 2.5rem;
  }
}

@media (max-width: 768px) {
  .input-pair {
    & > .input-pair_title {
      text-align: left;
    }
  }
}

textarea {
  background-color: #fff;
  border-color: #d9d9d9 #ccc #b3b3b3;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #333;
  cursor: default;
  border-spacing: 0;
  border-collapse: separate;
  outline: none;
  padding-left: 1rem;
  padding-right: 1rem;
}
[type='text'],
[type='password'],
[type='number'] {
  background-color: #fff;
  border-color: #d9d9d9 #ccc #b3b3b3;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #333;
  cursor: default;
  border-spacing: 0;
  border-collapse: separate;
  height: 36px;
  outline: none;
  padding-left: 1rem;
  padding-right: 1rem;
}

.input-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.error {
  color: red !important;
}

.error-border {
  border: 1px solid red !important;
  border-radius: 4px;
}

.form__error {
  margin: 0 0 $m-size 0;
  font-style: italic;
}