.button-container {
  display: flex;
  justify-content: center;
  background: #ddd;
  padding: 0.6rem;
  border-radius: 5px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3);

  button {
    flex-grow: 1;
    padding: 0.6rem 1.2rem;
    border-radius: 3px;
    font-weight: 400;
    transition: all 0.3s ease-in;

    &:first-of-type {
      margin-right: 0.3rem;
    }
    &:last-of-type {
      margin-left: 0.3rem;
    }

    &.button-active {
      background: #fafafa;
      border: 1px solid #fff;
      color: #050505;
      box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.33);

      &:hover {
        background: #fff;
      }
    }
    &.button-innactive {
      background: #eee;
      color: #777;
      border: 1px solid #f1f1f1;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

      &:hover {
        background: #f1f1f1;
      }
    }
  }
}
