* {
  box-sizing: border-box;
  &:focus {
    outline: 0;
  }
}
.breadcrumb {
  background-color: unset !important;
}
.breadcrumb > li + li:before {
  padding: 0 5px;
  color: #ccc;
  content: '/\00a0';
}
html {
  font-size: 10px;
}
.btn {
  font-size: 14px !important;
}
body {
  color: $dark-grey;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px !important;
  line-height: 1.6;
}

.open > .dropdown-menu {
  display: block !important;
  font-size: 14px;
}

.dropdown-menu li {
  margin: 10px;
  white-space: nowrap;
}

.dropdown-menu li a {
  color: black;
}

.open > .menuitem {
  padding: 0 20px;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

.is-active {
  font-weight: bold;
}

.content-container {
  position: relative;
  height: 100vh;
}

.fill-cell {
  background-color: transparent;
  border: 0;
  height: 100%;
  width: 100%;
}

.center {
  text-align: center;
}

.danger {
  color: red;
}

.green {
  color: green;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='checkbox']:checked {
  filter: hue-rotate(250deg);
}

button:disabled {
  color: #ddd;
}

.text-list {
  min-height: 100px;
  width: 100%;
}

.btn-light {
  color: #212529 !important;
  background-color: #e2e6ea !important;
  border-color: #dae0e5 !important;
}

.btn-warning {
  background-color: #d98f4e !important;
  color: #000 !important;
  border: 1px solid #d98f4e !important;
}

.btn-warning:hover {
  background-color: #edaf79 !important;
  color: #000 !important;
  border: 1px solid #edaf79 !important;
}

span,
p,
div,
input,
h1,
h2,
h3,
h4,
h5 {
  font-family: 'Poppins', sans-serif;
}

.modal-content {
  border-radius: 7px;
}

.close span {
  font-size: 30px;
  font-weight: 100;
}

.img-thumbnail {
  height: 120px !important;
}

.img-thumbnail image {
  height: 120px !important;
}

.flex-1 {
  flex: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.atlwdg-trigger {
  z-index: 1900000000 !important;
}

.item-row-office-select {
  .select-panel {
    padding-bottom: 500px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.bp3-popover {
  textarea {
    resize: both;
  }
}

.email-html.modal-dialog {
  max-width: unset;
  overflow: scroll;
  width: fit-content;
}

.default-page-padding {
  padding: 1rem 2rem;
}

.dropdown-select-input {
  border: 0;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
}

.react-dropdown-select-dropdown-add-new {
  display: none;
}

.main-alert-message {
  word-break: break-all;
}
