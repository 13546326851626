  
  .lfw-thin{
    font-weight: 100 !important;
  }

  .lfw-thin italic{
    font-weight: 100 !important;
  }

  .lfw-extra-light{
    font-weight: 200 !important;
  }

  .lfw-extra-light-italic{
    font-weight: 200 !important;
  }

  .lfw-light{
    font-weight: 300 !important;
  }

  .lfw-light-italic{
    font-weight: 300 !important;
  }

  .lfw-regular{
    font-weight: 400 !important;
  }

  .lfw-regular-italic{
    font-weight: 400 !important;
  }

  .lfw-medium{
    font-weight: 500 !important;
  }

  .lfw-medium-italic{
    font-weight: 500 !important;
  }

  .lfw-semi-bold{
    font-weight: 600 !important;
  }

  .lfw-semi-bold-italic{
    font-weight: 600 !important;
  }

  .lfw-bold{
    font-weight: 700 !important;
  }

  .lfw-bold-italic{
    font-weight: 700 !important;
  }

  .lfw-extra-bold{
    font-weight: 800 !important;
  }

  .lfw-extra-bold-italic{
    font-weight: 800 !important;
  }

  .lfw-black{
    font-weight: 900 !important;
  }

  .lfw-black-italic{
    font-weight: 900 !important;
  }