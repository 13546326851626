.priceguide__upcharge_header-container {
  background-color: $upChargeHeaderColor;
  border-bottom: 1px solid $upChargeBorderColor;
  border-top: 1px solid $upChargeBorderColor;
  display: flex;
  min-height: 2rem;
  .border-left {
    border-left: 1px solid $upChargeBorderColor;
  }
}

.priceguide__upcharge_header-cell {
  border-right: 1px solid $upChargeBorderColor;
  font-weight: 600;
  margin: 0;
  overflow-x: scroll;
  resize: horizontal;
  text-align: center;
  width: 200px;
  &_hidden {
    display: none;
  }
  &.static {
    min-width: 3.5rem;
    max-width: 3.5rem;
    overflow: hidden;
    resize: none;
  }
}

.priceguide__upcharge-row {
  background-color: $upChargeColor;
  display: flex;
  max-height: 35px;
  &_disabled {
    @extend .priceguide__upcharge-row;
    background-color: lighten($color: $upChargeColor, $amount: 2%);
  }
  .border-left {
    border-left: 1px solid $upChargeBorderColor;
  }
}

.can-drag {
  & .priceguide__upcharge-row {
    max-width: 100vw;
  }
}

.priceguide__upcharge-cell {
  align-items: center;
  border-bottom: 1px solid $upChargeBorderColor;
  border-right: 1px solid $upChargeBorderColor;
  display: flex;
  flex: none;
  background-color: $upChargeColor;
  flex-direction: column;
  position: relative;
  text-align: center;
  width: 200px;
  .delete-upcharge {
    border-left: 1px solid $upChargeBorderColor;
  }
  input {
    background-color: transparent;
    border: 0;
    width: 100%;
  }
  input[type='checkbox'] {
    margin: auto;
    width: unset;
  }
  &_hidden {
    display: none;
  }
}

.priceguide__upcharge-prices-container {
  border-left: 1px solid $upChargeBorderColor;
  border-right: 1px solid $upChargeBorderColor;
  display: flex;
  flex-direction: row;
  max-width: 20rem;
  min-width: 20rem;
  position: relative;
}

.priceguide__upcharge-calculated_total-label {
  bottom: 0.2rem;
  cursor: help;
  font-size: 1rem;
  font-weight: 900;
  position: absolute;
  right: 0.5rem;
  &:disabled {
    color: #ddd;
  }
}

.priceguide__link-label {
  color: $upChargeBorderColor;
  cursor: help;
  font-size: 1rem;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  &:disabled {
    color: #ddd;
  }
}

.priceguide__number-type-label {
  font-size: 1rem;
  font-weight: bold;
  left: 0.5rem;
  position: absolute;
  top: 0;
}
