.pagination-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.pagination-select-container {
  align-items: center;
  color: $light-grey;
  display: flex;
  flex-direction: row;
  margin: 2rem 0.25rem;
}

.pagination-select {
  margin: 0 0.75rem;
  width: 8rem;
}

.pagination-paginator {
  align-self: left;
  padding: 5rem;
}

.no-count-page-control {
  margin-right: 60px;
}

.no-count-page-control .page-link {
  padding: 0.5rem 10px;
}

.side-panel-paginator {
  .pagination-paginator {
    padding: 2.5rem 0;
    justify-content: flex-end;
  }
}

@media (max-width: 768px) {
  .pagination-container {
    flex-direction: column;
  }
  .pagination-select-container {
    margin-bottom: 0;
  }
  .pagination-paginator {
    padding: unset;
    margin-top: 2rem;
  }
  .no-count-page-control {
    margin-right: unset;
    margin-top: 2rem;
  }
}
