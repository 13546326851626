.priceguide__index {
  position: relative;
  height: 100%;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
}
.bp3-portal {
  z-index: 999;
}
.priceguide__rows_container {
  position: relative;
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
}

.priceguide__container {
  overflow-y: hidden;
  overflow-x: scroll;
  position: relative;
  width: 100%;
}

.priceguide__expand-all-button {
  width: 35px;
  flex: none;
  button {
    background-color: transparent;
    border: 0;
    height: 100%;
    width: 100%;
  }
  &_item-header {
    @extend .priceguide__expand-all-button;
    border-bottom: 0;
    border-left: 1px solid $msiBorderColor;
    border-right: 1px solid $msiBorderColor;
  }
  &_item {
    @extend .priceguide__expand-all-button;
    border-bottom: 1px solid $msiBorderColor;
    border-left: 1px solid $msiBorderColor;
    border-right: 1px solid $msiBorderColor;
  }
  &_option-header {
    @extend .priceguide__expand-all-button;
    border-bottom: 1px solid $optionBorderColor;
    border-left: 1px solid $optionBorderColor;
    border-right: 1px solid $optionBorderColor;
  }
  &_option {
    @extend .priceguide__expand-all-button;
    border-bottom: 1px solid $optionBorderColor;
    border-left: 1px solid $optionBorderColor;
    border-right: 1px solid $optionBorderColor;
  }
}

.priceguide__expand-all-label-left {
  bottom: 0;
  cursor: help;
  display: flex;
  font-size: 8px;
  font-weight: 600;
  justify-content: space-between;
  padding: 0 3px;
  position: absolute;
  width: 100%;
}

.priceguide__button {
  background-color: transparent;
  border: 0;
  height: 100%;
  padding: 0;
  vertical-align: middle;
  width: 100%;
  :disabled {
    cursor: not-allowed;
    opacity: 50%;
  }
}

.priceguide__input-currency {
  background-color: transparent;
  border: 0;
  padding-left: 1.7rem;
  width: 100%;
}

.priceguide__office-name-container {
  display: flex;
  height: 100%;
}

.priceguide__office-name {
  height: 100%;
  text-align: center;
  width: 20rem;
  &-option {
    @extend .priceguide__office-name;
    border-right: 1px solid $optionBorderColor;
  }
  &-upcharge {
    @extend .priceguide__office-name;
    border-left: 1px solid $upChargeBorderColor;
    border-right: 1px solid $upChargeBorderColor;
  }
}

.priceguide__thumbnail-button {
  height: 100%;
  position: relative;
  width: 100%;
  button {
    background: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;
    img {
      position: relative;
      width: 100%;
      height: 35px;
    }
    &:disabled {
      img {
        opacity: 0.7;
      }
    }
  }
  input {
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.priceguide__drag-container {
  color: rgb(119, 119, 119);
  display: flex;
  height: 100%;
  text-align: center;
  width: 100%;
  &:hover {
    opacity: 0.5;
  }
  i {
    margin: auto;
  }
}
