.package__thumbnail {
  text-align: center;
  width: 30rem;
  div {
    position: relative;
    background-color: transparent;
    border: 0;
    display:flex;
    align-items: center;
    width:100%;
    img {
      position: relative;
      max-height: 6.5rem;
      object-fit: contain;
      width: 100%;
    }
  }
}

.package__name {
  margin: 0;
  padding: 0;
  text-align: center;
  div {
    background-color: transparent;
    border: 0;
    display: block;
    font-size: 2rem;
    font-weight: 700;
    padding: 2rem;
    text-align: center;
    width: 100%;
    height: 75px;
  }
}

.package__action {
  text-align: center;
  width: .35rem;
}