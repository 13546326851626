.estimates-center-stage-inner {
  flex: 1;
}

.email-estimate-list-item {
  overflow: hidden;
  &:nth-child(odd) {
    background: $medium-grey;
  }

  .email-estimate-list-item-statusbox {
    min-width: 250px;
  }

  .email-estimate-list-item-title {
    flex-wrap: wrap;
    align-items: baseline;

    .list-line-header {
      margin-right: 10px;
      margin-bottom: 10px;
    }

    .list-line-action-item {
      margin-right: 10px;
      margin-bottom: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.page-title {
  margin-bottom: 30px;
  font-size: 52px;
  font-weight: 800;
}

.page-title::after {
  content: '';
  position: relative;
  top: 10px;
  display: block;
  background-color: #26d07c;
  height: 8px;
  width: 100px;
  border-radius: 10px;
}

.section-title {
  margin-bottom: 30px;
  font-size: 48px;
  font-weight: 700;
}

@media (min-width: 768px) {
  .section-title {
    font-size: 28px;
  }
  .section-title::after {
    width: 130px;
  }
}

.section-title::after {
  content: '';
  position: relative;
  top: 10px;
  display: block;
  background-color: #26d07c;
  height: 8px;
  width: 230px;
  border-radius: 10px;
}

.lil-green-blurb::after {
  content: '';
  position: relative;
  top: 10px;
  display: block;
  background-color: #26d07c;
  height: 8px;
  width: 80px;
  border-radius: 10px;
}

.title-section {
  margin-bottom: 80px;
  margin-top: 40px;
}

.title-section-header {
  font-size: 48px;
  font-weight: 700;
}

.list-line {
  padding: 40px 25px;
}

.list-line-header {
  font-size: 24px;
  font-weight: 600;
}

.list-line-action-item {
  font-size: 16px;
  font-weight: 600;
}

.filter-section-dropdown-title {
  flex: 0.3;
  margin-right: 0.5rem;
}

@media (max-width: 768px) {
  .filter-section-input-row {
    flex-direction: column;
    > * {
      margin: unset;
      width: 100%;
    }
  }
  .filter-section-dropdown-title {
    margin: unset;
  }
}

.leap-field-title {
  font-size: 24px;
  font-weight: 700;
}

.field-display-list-value {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 12px;
  overflow-wrap: break-word;
}

.field-display-list-value p {
  margin: 0;
  font-size: 18px;
  font-weight: 300;
}

.customer-meta-section {
  dt {
    font-size: 2.5rem;
  }

  dd {
    font-size: 18px;
    // font-weight: 300;
  }

  .addressList {
    margin-bottom: 30px;
  }

  .orderedList {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow-wrap: break-word;

    & > li {
      margin-bottom: 12px;
    }
  }
}

.customer-secure-sign-section {
  dt {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    margin-top: 2.5rem;
  }

  dd {
    font-size: 18px;
    // font-weight: 300;
  }

  .addressList {
    margin-bottom: 30px;
  }

  .orderedList {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow-wrap: break-word;

    & .name {
      font-weight: bold;
    }
    :last-child {
      display: flex;
      & > button {
        position: relative;
        left: 10px;
        min-width: 78px;
        top: -10px;

      }
    }
    & > li {
      font-size: 16px;
    }
  }
}

.leap-action-icon-link {
  color: #007bff !important;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
}
.leap-action-icon {
  height: 67px;
}

.list-line-note {
  font-size: 12px;
  margin-top: 5px;
  font-weight: 300;
}

.user-line-symbol {
  font-size: 12px;
  color: #26d07c;
  font-weight: 600;
}

.user-line-name {
  font-size: 16px;
  margin-left: 5px;
  font-weight: 600;
}

.user-line-date {
  font-size: 16px;
  margin-left: 5px;
  font-weight: 300;
  border-left: 1px solid #333;
  padding-left: 10px;
  margin-left: 10px;
}

.leap-data-section {
  margin-bottom: 80px;
}

.leap-row-space {
  margin-bottom: 40px;
}

.input-title {
  font-size: 23px;
}

.input-error-message {
  font-size: 15px;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.ml-10 {
  margin-left: 10px;
}

.mr-50 {
  margin-right: 50px;
}

.ml-50 {
  margin-left: 50px;
}

.logs-header {
  margin-bottom: 30px;
  margin-top: 60px;
  font-size: 48px;
  font-weight: 800;
}

.logs-table {
  margin-bottom: 40px;

  .logs-table-keymetric {
    font-weight: 600;
  }

  th {
    text-align: center;
  }

  tbody th {
    font-weight: 500;
  }
}

.payments-methods-section {
  table {
    cursor: pointer;
  }
  .r-table-sm:hover {
    color: #212529;
    background-color: rgba(0, 0, 0, 0.075);

    & tr td:first-child {
      background-color: unset;
    }
  }

  .r-table-sm tr td:first-child {
    width: 120px;
  }
}

.r-table-sm tr td:first-child {
  background-color: whitesmoke;
}
