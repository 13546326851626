.new-account__container {
  background-color: #e6e6e6;
  height: 100%;
  min-height: 100vh;
  padding: 50px 100px;
  width: 100%;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .new-account__container {
    background-color: #e6e6e6;
    height: 100%;
    overflow: scroll;
    padding: 50px 25px;
    width: 100%;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .new-account__container {
    background-color: #e6e6e6;
    height: 100%;
    overflow: scroll;
    padding: 50px 25px;
    width: 100%;
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}

.new-account__copyright {
  bottom: 0px;
  color: #333333;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  height: 30px;
  left: 0px;
  margin-top: 5px;
  right: 0px;
  text-align: center;
  width: 100%;
}

.new-account__body-container {
  background-color: white;
  min-height: 100%;
  padding: 25px;
  position: relative;
  width: 100%;
}

.new-account__header-container {
  background-color: white;
  height: 192px;
  margin: 0 0 20px 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
}

.new-account__header-image-conatiner {
  overflow: hidden;
  position: relative;
  img {
    left: -75px;
    position: relative;
    top: -180px;
  }
}

.new-account__header-oc-logo {
  background: white;
  height: 68px;
  position: absolute;
  right: 0px;
  text-align: right;
  top: 0px;
  width: 110px;
  img {
    max-height: 100%;
  }
}

.new-account__header-prosell-logo {
  background-color: white;
  bottom: 0px;
  padding: 20px 0 20px 30px;
  position: absolute;
  right: 0px;
  width: 45%;
  max-height: 124px;
  max-width: 372px;
}

.new-account__footer-conatiner {
  margin-top: 20px;
  width: 100%;
}

.prosell-logo {
  width: 100%;
}

.new-account__download-app-store-container {
  background-color: #e6e6e6;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 30px;
  img {
    max-height: 52px;
  }
}

// Signup Page
.new-account__signup-body {
  min-height: 410px;
}

.new-account__sign-up-now {
  color: #000000;
  font-size: 44px;
  font-weight: 600;
  height: 48px;
  line-height: 48px;
  width: 432px;
}

.new-account__text-input-group {
  label {
    display: flex;
    flex-direction: column;
    font-family: Arial, Helvetica, sans-serif;
  }
  input {
    color: #65666a;
  }
}

.new-account__prosell-submit {
  background-color: $highlight;
  border: 0;
  border-radius: 4px;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 500;
  height: 35px;
  margin: 10px 0;
  padding: 0 30px;
  cursor: pointer;
  &:disabled {
    color: white;
    cursor: not-allowed;
    opacity: 0.5;
  }
  &-prosell {
    @extend .new-account__prosell-submit;
    background-color: #d40f7d;
  }
}

.new-account__account-info-group {
  display: flex;
  flex-wrap: wrap;
}

.new-account_account-info-title {
  font-weight: 800;
  max-width: 180px;
  min-width: 180px;
}

.new-account__body-header {
  font-size: 20px;
  font-weight: bold;
}

.new-account__terms-container {
  border: 1px solid lightgray;
  border-radius: 4px;
  max-height: 250px;
  overflow: scroll;
  padding: 5px;
}

.new-account__accept-container {
  font-weight: bold;
  margin-top: 10px;
  input[type='checkbox'] {
    cursor: pointer;
    margin-right: 8px;
  }
}

// Leap
.new-account__hero-img-container {
  position: relative;
  img {
    height: 100%;
    position: relative;
    top: -50px;
    width: 100%;
  }
}

.new-account__leap-header-logo {
  bottom: 0;
  height: 40%;
  margin-left: 30px;
  padding: 10px;
  position: absolute;
  img {
    height: 100%;
    object-fit: contain;
  }
}

.new-account__hero-text {
  color: white;
  font-size: 25px;
  padding: 20px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
}
