.custom-options__action-column {
  width: 3.5rem;
}

.custom-options__action-cell {
  display: flex;
  flex-direction: row;
  > * {
    margin: .5rem;
  }
}