.app-appearance-header-cell {
  border: 1px solid #ddd;
  font-weight: 500;
  text-align: center;
}

.app-appearance-row {
  width: 100%;
  > td {
    vertical-align: middle;
  }
}

.app-appearance-cell {
  border: 1px solid #ddd;
  margin: auto;
  vertical-align: middle !important;
  width: 50%;
}

.app-appearance-cell-title {
  font-weight: 400;
  margin: auto;
  text-align: center;
}

.app-appearance-cell-value {
  border: 1px solid transparent;
  border-radius: 4px;
  border-color: #ddd;
  box-shadow: 0 1px 1px rgba(0,0,0,.05);
  padding: 8px;
  font-size: 12px;
  > input {
    cursor: text;
    width: 100%;
  }
}