.loader {
  align-items: center;
  display: fixed;
  height: 100vh;
  justify-content: center;
  width: 100vw;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.loader__image {
  height: 6rem;
  width: 6rem;
}