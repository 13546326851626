.users-active-button-group {
  margin-bottom: .5rem;
}

.users-active {
  background-color: #93ce87;
}

.users__assigned-office-container {
  color: $light-grey;
}