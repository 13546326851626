/* Styles to default/baseline editor section */

.restore-autosave-modal-container {
  max-width: 90%;
}

.contract-editor {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.428571429;
  div,
  h1,
  h2,
  h3,
  h4,
  h5,
  input,
  p,
  span {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
}

/* Firefox (any) */
.contract-editor {
  height: -moz-fit-content;
}

.contract-editor {
  height: fit-content;

  embed {
    min-height: 792px;
    width: 100%;
  }
}

.templates-pdf__background {
  display: block;
  position: relative;
  background-color: lightgray;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.templates-pdf__toolbar {
  width: 100%;
}

.templates-pdf__page-count {
  font-size: 10px;
  position: absolute;
  right: 2rem;
  top: 0.5rem;
}

.linked-object-none {
  color: #999999 !important;
  font-weight: normal !important;
  &:hover {
    color: #2e2e2e !important;
    font-weight: bold !important;
  }
  &:active {
    color: #2e2e2e !important;
    font-weight: bold !important;
  }
}

.linked-object-text {
  &:hover {
    color: #2e2e2e !important;
    font-weight: bold !important;
  }
  &:active {
    color: #2e2e2e !important;
    font-weight: bold !important;
  }
}

.linked-menu-container {
  margin-bottom: 5px;
}

.linked-menu-input-wrapper {
  outline: 0 !important;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 4px;
  border: 0.5px solid #4779ff;
}

.linked-menu-input-main {
  outline: 0 !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  padding: 4px 6px;
  height: 100%;
  flex: 1;
}

.linked-menu-input-secondary {
  outline: 0 !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  padding: 2.5px 4px;
  height: 100%;
  flex: 1;
}

.linked-menu-list-container {
  max-height: 115px;
  width: 200px;
  overflow-y: auto;
}

.linked-menu-modal-title {
  font: normal normal bold 16px/25px Poppins;
  color: #f1001f;
}

.linked-menu-no-objects {
  color: #999999;
  font: normal normal normal 14px/16px Arial;
  margin-top: 10px;
}

.linked-menu-icons-container {
  display: flex;
  gap: 8px;
}

.linked-menu-list {
  margin-top: 4px;
  margin-bottom: 4px;
}

.linked-menu-list li a {
  display: felx;
  align-items: center;
  height: 23px;
}

.linked-menu-cursor-pointer {
  cursor: pointer;
}

.contract-text-menu-container::-webkit-scrollbar {
  width: 0 !important;
}

textarea::-webkit-scrollbar {
  width: auto !important;
}

textarea::-webkit-scrollbar {
  width: auto !important;
}

.bp3-menu {
  max-height: 80vh !important;
  overflow: scroll;
}

.net-wrapper-inner-net {
  position: absolute;
  z-index: -1;
  top: -1000px;
  height: 3000px;
  width: 3000px;
}

.bp3-menu-item {
  overflow: hidden;
}

.templates-pdf__canvas {
  display: flex;
  justify-content: center;
  height: 100%;
  overflow: scroll;

  > div {
    margin: 20px;
    box-shadow: 0 3px 6px -3px rgba(0, 0, 0, 0.5);
  }

  &.toolbar_active {
    > div {
      margin-top: 52px;
      margin-bottom: 100px;
    }
  }
}

.both-view {
  justify-content: unset;
}

.templates-pdf__watermark {
  align-items: center;
  bottom: 0;
  display: flex;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.templates-pdf__tree-container {
  left: 0;
  max-width: 20rem;
  position: absolute;
  top: 20rem;
  z-index: 1;
}

.templates-pdf__cell-tool-drawer {
  position: absolute;
  right: 0;
  top: 20rem;
  z-index: 1;
}

.cell-draw-menu-item li {
  list-style-type: none;
  background-color: lightgray;
  padding: 0 5px;
  border-radius: 10px;
}

.cell-draw-menu-item p {
  list-style-type: none;
  background-color: lightgray;
  padding: 0 5px;
  border-radius: 10px;
}

.both-view {
  .templates-app__container {
    min-width: 768px;
  }
}

.templates-app__container {
  background-color: white;
  border-radius: 4px;
  width: 768px;
  min-height: 1024px;
  margin-top: 100px;
  overflow-x: hidden;
}

.templates-app__navbar {
  background-color: rgb(247, 247, 247);
  font-size: 18px;
  font-weight: bold;
  max-height: 55px;
  min-height: 55px;
  padding: 20px;
  position: relative;
  text-align: center;
  width: 100%;
}

.templates-app__navbar-back-button {
  background-color: transparent;
  border: 0;
  font-weight: normal;
  position: absolute;
  left: 0;
  margin-left: 15px;

  &:disabled {
    color: 'darkgray';
    cursor: 'not-allowed';
  }
}

.templates-app__content {
  max-width: $appContainerWidth;
  min-width: $appContainerWidth;
  left: 0;
  position: relative;
  transition: left 0.3s cubic-bezier(0, 0.52, 0, 1);
}

.templates-app__content_offset {
  max-width: $appContainerWidth;
  min-width: $appContainerWidth;
  position: relative;
  left: -100%;
  transition: left 0.3s cubic-bezier(0, 0.52, 0, 1);
}

.templates-app__content_more {
  max-width: $appContainerWidth;
  left: 0;
  position: relative;
  transition: all 0.3s cubic-bezier(0, 0.52, 0, 1);
  min-width: 0;
  position: relative;
  width: 0;
  overflow-x: hidden;
}

.templates-app__content_more_offset {
  max-width: $appContainerWidth;
  min-width: $appContainerWidth;
  position: relative;
  left: -100%;
  transition: all 0.3s cubic-bezier(0, 0.52, 0, 1);
}

.templates-app__detail-content {
  @extend .templates-app__content;
  max-width: $appContainerWidth;
  min-width: $appContainerWidth;
}

.templates-app__detail-content_offset {
  @extend .templates-app__content_offset;
  max-width: $appContainerWidth;
  min-width: $appContainerWidth;
}

.templates-app__header_cell {
  align-items: center;
  background-color: rgba(229.5, 229.5, 229.5, 1);
  display: flex;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 17px;
  font-weight: bold;
  justify-content: space-between;
  max-height: 44px;
  padding: 9px 15px;
  cursor: pointer;
  position: relative;
  width: 100%;
}

.templates-app__cell_bottom_border {
  position: absolute;
  border-bottom: 1px solid darkgray;
  bottom: 0;
  left: 15px;
  right: 0;
}

.templates-app__text_cell {
  align-items: center;
  background-color: white;
  display: flex;
  justify-content: space-between;
  min-height: 44px;
  padding: 4px 15px;
  position: relative;
  width: 100%;
}

.templates-app__text_cell_paragraph {
  background-color: white;
  display: flex;
  flex-direction: column;
  min-height: 44px;
  padding: 11.5px 15px;
  position: relative;
  width: 100%;
}

.templates-app__title {
  color: black;
  cursor: pointer;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 17px;

  &:hover {
    opacity: 0.7;
  }
}

.templates-app__title_required {
  @extend .templates-app__title;
  color: red;
}

.templates-app__note {
  font-size: 11px;
  color: #555555;
  cursor: pointer;
}

.templates-app__value {
  color: #0072cc;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.templates-app__disabled {
  color: darkgray;
}

.templates-app__detail_cell {
  background-color: white;
  padding: 8px 15px;
  position: relative;
  width: 100%;
}

.templates-app__detail_text_cell {
  align-items: center;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  position: relative;
  width: 100%;
}

.templates-app__detail_title {
  @extend .templates-app__title;
  font-size: 12px;
  font-weight: bold;
}

.templates-app__detail_title_required {
  @extend .templates-app__detail_title;
  color: red;
}

.templates-app__detail_value {
  @extend .templates-app__value;
  cursor: pointer;
  font-size: 12px;
}

.templates-app__stacked {
  display: flex;
  flex-direction: column;
}

.templates-app__line-text {
  width: 645px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.templates-app__photo_selector_cell {
  align-items: center;
  background-color: white;
  display: flex;
  justify-content: space-between;
  max-height: 118px;
  min-height: 118px;
  padding: 8px 15px;
  position: relative;
  width: 100%;
}

.templates-app__photo_selector_cell_image {
  max-height: 100%;
  min-height: 100%;
  max-width: 101px;
  min-width: 101px;
}

.templates-app__drawing_cell {
  @extend .templates-app__stacked;
  background-color: white;
  padding: 8px 15px;
  position: relative;
  width: 100%;
}

.templates-app__measure_sheet_detail {
  align-items: center;
  background-color: white;
  cursor: pointer;
  display: flex;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 17px;
  min-height: 44px;
  padding: 4px 15px;
  position: relative;

  &:hover {
    opacity: 0.7;
  }
}

.templates-app__provia {
  display: flex;
  padding: 3px 6px;
}

.templates-app__provia_quantity_box {
  background-color: rgba(224, 224, 224, 0.7);
  border-radius: 5px;
  max-height: 75px;
  max-width: 75px;
  min-height: 75px;
  min-height: 75px;
  margin: 6px 3px 0 3px;
}

.templates-app__provia_image_label {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 8px;
  text-align: center;
  width: 100%;
}

.templates-app__provia_quantity_label {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 18px;
  padding: 3px;
  text-align: center;
  width: 100%;
}

.templates-app__provia_quantity_number {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 40px;
  font-weight: bold;
  margin-top: -10px;
  padding: 0 3px;
  text-align: center;
  width: 100%;
}

.templates-app__provia_details {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 8px;
  margin: 0 3px 0 3px;
}

.templates-app__provia_details_bold {
  @extend .templates-app__provia_details;
  font-size: 9px;
  font-weight: bold;
}

.templates-app__image_selector_container {
  background-color: white;
  min-height: 118px;
  min-width: 100%;
  padding: 4px;
  position: relative;
  width: 100%;
}

.templates-app__image_selector_scroll-view {
  display: flex;
  min-height: 85px;
  min-width: 85px;
  overflow: scroll;
}

.templates-app__image_selector_tile {
  padding: 2px;
  text-align: center;
}

.templates-app__image_selector_tile_default {
  @extend .templates-app__image_selector_tile;
  border: 2px solid $highlight;
  padding: 0;
}

.templates-app__image_selector_image {
  max-height: 85px;
  min-height: 85px;
  max-width: 85px;
  min-width: 85px;
  object-fit: contain;
}

.templates-app__image_selector_label {
  height: 18.5px;
  text-align: center;
}

// ************* PDF ********************* //
.templates-single_line {
  white-space: nowrap;
}

.templates-pdf__text {
  font-family: Helvetica, Arial, sans-serif;

  &:hover {
    opacity: 0.7;
  }
}

.templates-pdf__text .bp3-popover-target {
  position: relative;
  width: 100%;
  display: flex;
  flex: 1;
}

.templates-pdf__text .bp3-popover-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex: 1;
}

.fill-parent-abs {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.drawing-img-container {
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.fill-parent-abs {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.drawing-background-input-containter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}

.drawing-background-input-containter:hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

.fill-parent-rel {
  position: relative;
  width: 100%;
  height: 100%;
}

.drawing-text-container {
  position: relative;
  z-index: 10;
}

.drawing-background-img {
  position: relative;
  height: 100%;
  text-align: center;
}
