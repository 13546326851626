.document-tree__container {
  background-color: #f1f1f1;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  max-width: 250px;
  min-width: 250px;
  position: absolute;
  z-index: 10;
}

.slide_left {
  transform: translateX(-100%);
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
}

.slide_right {
  transform: translateX(0);
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
}

.document-tree__header {
  align-items: center;
  background-color: #2196f3;
  border-top-right-radius: 5px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  text-align: center;
  width: 100%;
}

.document-tree__add-button {
  cursor: pointer;
  margin-right: 5px;
}

.document-tree__body {
  max-height: 400px;
  overflow: scroll;
}

.document-tree__collapse {
  background-color: transparent;
  border: 0;
  color: white;
  cursor: pointer;
  margin-left: 5px;
}

.document-tree__expand_open {
  display: none;
}

.document-tree__expand_closed {
  background-color: #2196f3;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  right: -30px;
  top: 5px;
  color: white;
}

.cell-tool-drawer {
  position: relative;
  transition: left 0.3s;
}

.show_left {
  left: 0%;
}

.hide_right {
  left: 200px;
}

.cell-tool-drawer__header {
  align-items: center;
  background-color: #2196f3;
  border-top-left-radius: 5px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  text-align: center;
  width: 100%;
}

.cell-tool-drawer__expand_open {
  display: none;
}

.cell-tool-drawer__expand_closed {
  position: absolute;
  right: 5px;
  background-color: #2196f3;
  border-radius: 50%;
  cursor: pointer;
  color: white;
}

.cell-tool-drawer__collapse {
  background-color: transparent;
  border: 0;
  color: white;
  cursor: pointer;
}
