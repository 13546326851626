/* Main speed dial container */
.speed-dial {
  z-index: 1050;
  display: flex;
  align-items: center;
  pointer-events: none;
  flex-direction: column-reverse;
  position: absolute;
  bottom: 23px;
  right: 23px;
}

/* Main button (the "+" or "x" button) */
.speed-dial__main {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  text-decoration: none;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-height: 36px;
  min-width: 0;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  z-index: 1050;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14);
  background-color: #1e0044;
  color: #fff;
  pointer-events: auto;
  transform: none;
  transition: transform 0.3s ease-in-out;
  margin-left: 3px;
}

.speed-dial__icon-root {
  height: 24px;
}

.speed-dial__icon-root img {
  font-size: 0.67rem;
}

.speed-dial__main--rotate {
  transform: rotate(45deg);
}

/* Action buttons (hidden by default) */
.speed-dial__actions {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: -32px;
  padding-bottom: 48px;

  opacity: 0;
  pointer-events: none;
  transition: transform 2000ms cubic-bezier(0.4, 0, 0.2, 1), opacity 0.8s ease;
}

.speed-dial__actions--show {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0);
}

.speed-dial__actions__image {
  color: white;
  width: 2em;
  vertical-align: middle;
  border: 0;
  overflow-clip-margin: content-box;
  overflow: clip;
  font-size: 1.4rem;
}

/* Action button item */
.speed-dial__item {
  position: relative;
}

.speed-dial__item:hover .popover {
  opacity: 1;
}

/* Action button styling */
.speed-dial__action {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  outline: 0;
  border: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  text-decoration: none;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-height: 36px;
  width: 45px;
  height: 45px;
  z-index: 1050;
  border-radius: 50%;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14);
  color: rgba(0, 0, 0, 0.6);
  margin: 8px;
  background-color: #1e0044;
  opacity: 0;
  transform: scale(0);
  transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    opacity 0.8s ease;
  transition-delay: 60ms;
}

.speed-dial__action:hover {
  transform: scale(1.1);
}

/* Show action buttons on hover */
.speed-dial__actions--show .speed-dial__action {
  opacity: 1;
  transform: scale(1);
  transition-delay: 0ms;
}

.speed-dial__actions:not(.speed-dial__actions--show) .speed-dial__action {
  opacity: 0;
  transform: scale(0);
  transition-delay: 0ms; /* Delayed transition for smooth hiding */
}

/* Button focus and hover state */
.speed-dial__main:focus,
.speed-dial__action:focus {
  outline: none;
}

/* Popover styling */
.popover {
  position: absolute;
  /* left: -60px; */
  top: 50%;
  transform: translateY(-50%);
  background-color: #616161;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 10px;
  white-space: nowrap;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  opacity: 1;
  transition: opacity 0.3s ease;
  width: auto; /* Set width to auto */
}

.popover--video {
  left: -75px;
}

.popover--chat {
  left: -45px;
}
