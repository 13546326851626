.priceguide-placeholders_container {
  max-height: 20rem;
  max-width: 50rem;
  overflow: scroll;
  padding: 5px;
}

.priceguide-placeholders_header-cell {
  font-weight: bold;
  width: 50%;
}

.priceguide-placeholders_row {
  max-width: 100%;
  min-width: 100%;
}

.priceguide-placeholders_cell {
  min-width: 10rem;
}

.priceguide-placeholders_disclosure {
  color: lightgray;
  font-size: 1.2rem;
  margin: 5px;
  text-align: center;
  width: 100%;
}