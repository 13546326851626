.button {
    background: $blue;
    border: none;
    color: white;
    display: inline-block;
    font-size: $font-size-large;
    font-weight: 300;
    line-height: 1;
    padding: $s-size;
    text-decoration: none;
  }

  .button--link {
    background: none;
  }

  .button--secondary {
    background: #888;
  }