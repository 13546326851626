.main-view {
  position: relative;
  display: flex;
  height: 100%
}

.content-view {
  position: relative;
  flex: 1;
  overflow: scroll;
  padding-right: 1.7em;
  padding-left: 1.7em;
}

.app-toaster{
  z-index: 999;
}

.custom-panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-color: #ddd;
  border-radius: 4px;
}

.custom-panel__header {
  color: #333;
  background-color: #f5f5f5;
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.custom-panel__header-title {
  border-bottom: .2rem solid #E6E9ED;
  padding: 0 .5rem .6rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between; // border-radius: .5rem;
}

.custom-panel__title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: inherit;
}

.custom-panel__body {
  padding: 15px;
}

// .page-section {
//     margin-bottom: 20px;
//     background-color: #fff;
//     border: 1px solid transparent;
//     border-radius: 4px;
//     border-color: #ddd;
//     -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
//     box-shadow: 0 1px 1px rgba(0,0,0,.05);
//     line-height: 1.471;
// }
.page-section__header {
  background-color: #f5f5f5;
  border-color: #ddd;
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.page-section__body {
  padding: 15px;
}
