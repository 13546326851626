$black: #000;
$blue-btn-outline: #0067c5;
$blue-btn: #0067c5;
$blue-dark: #0067c5;
$blue-light: #c6e1fc;
$blue-white: #f7fbff;
$blue-navy: #00284b;
$blue: #a8d4ff;
$disabled-btn: #e5e5e5;
$green-btn-outline: #24c475;
$green-btn: #26d07c;
$green-dark: #109553;
$green-light: #f4ffef;
$green-white: #fefffd;
$green-toggle: #4cd964;
$green: #e9ffde;
$grey: #666666;
$grey-dark: #6c6c6c;
$grey-btn: #6c6c6c;
$grey-btn-outline: #6c6c6c;
$grey-light: #e5e5e5;
$grey-white: white;
$orange-btn-outline: #ce8a4f;
$orange-btn: #d98f4e;
$orange-dark: #ff7700;
$orange-light: #fdd8b4;
$orange-white: #ffeede;
$orange: #ffcc9f;
$text-dark: #292929;
$text-light: #fefefe;
$text-disabled: #6c6c6c;
$white: #fff;

$button-border-width: 1px;
$button-padding: 0.7rem 1.1rem;
$button-border-radius: 5px;

$drop-shadow: 0 3px 6px rgba(0, 0, 0, 0.26);
