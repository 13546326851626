.sumbit-payment-page-outer {
  background: $off-white;
  min-height: 100vh;
  overflow: hidden;
}

.sumbit-payment-page {
  margin-top: 10vh;
  padding-top: 60px;
  position: relative;
  flex: 1;
  background: fade-out(white, $amount: 0.15);
  border-radius: 3px;
  border: 1px solid #ddd;
  height: fit-content;
  padding: $l-size $m-size;

  .submit-payment-form-selector-box {
    margin-bottom: 2rem;
  }

  .form-row {
    label {
      font-weight: 600;
    }
  }
}

@media (max-width: 767.98px) {
  .sumbit-payment-page {
    margin-top: 10px;
  }
}

.card-date-inputs {
  text-align: center;
  widows: 45px;
}

.card-date-input-label {
  margin-left: 10rem;
  margin-right: 2.2rem;
  margin-bottom: 0 !important;
}

.leap-logo-container {
  height: 30px;
  text-align: center;
  width: 100%;
  margin-bottom: 30px !important;
}

.credit-app__disclosure {
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.3rem;
  max-height: 150px;
  overflow-y: scroll;
  padding: 10px;
}

.submit-payment-requested-by-label {
  font-size: 13px;
  color: #333;
  font-weight: 400;
}

.submit-payment-requested-by-name {
  font-size: 24px;
}

.submit-payment-requested-by-message {
  font-size: 12px;
  margin-bottom: 2rem;
}

.amount-text-value {
  font-size: 52px;
  color: #26d07c;
  font-weight: 700;
}

.amount-text-label {
  font-size: 16px;
  color: #333;
  font-weight: 400;
  margin-bottom: 0;
}

.sending-company-logo {
  position: relative;
  object-fit: contain;
  display: block;
  margin-bottom: 20px;
  width: 180px;
  height: auto;
  margin-bottom: 20px;
}

.submit-spinner {
  height: 155.5px;
  margin-top: 100px;
}

.thank-you-pane {
  margin-top: 30px;
  p {
    font-size: 26px;
  }
  .submited-text {
    color: #26d07c;
  }
}
