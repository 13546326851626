.email-links__list {
  padding: 0;
}
.email-links-center {
  width: 25%;
  padding: 0 .8rem 0 .8rem;
  text-align: center;
}

.email-link__link-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.email-link__row {
  align-items: center;
  display: flex;
  width: 100%;
}
.email-link__row-content {
  align-items: center;
  display: flex;
  padding: .5rem;
  > * {
    width: 100%;
  }
}