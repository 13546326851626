.resources__tab-title {
  background-color: #fff;
  border-color: #d9d9d9 #ccc #b3b3b3;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #333;
  cursor: default;
  border-spacing: 0;
  border-collapse: separate;
  height: 36px;
  outline: none;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
}

.modal-dialog.new-resource-modal {
  max-width: 90%;
}

.resources-thumbnail-container {
  display: flex;
  flex-direction: column;
}

.resources-thumbnail {
  width: 100%;
  height: 20rem;
  object-fit: contain;
  margin-bottom: 0.5rem;
}

.resources-tabs-table {
  max-width: 100%;
  th {
    text-align: center;
  }
}

.clear-button {
  background: transparent;
  border-radius: 100%;
  padding: 0;
  margin: 0;
  border: 0;
  top: 1rem;
  right: 2.5rem;
  position: absolute;
  font-size: 20px;
}
