.watermark-image-container {
  align-items: center;
  border: 1px solid #cacccd;
  border-radius: 0.4rem;
  display: flex;
  height: 38.5rem;
  justify-content: center;
  margin: auto;
  width: 29.75rem;
}

.watermark-image {
  margin: auto;
  object-fit: contain;
  text-align: center;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.no-callout {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
