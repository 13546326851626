// Multiselect values
// .Select--multi {

//   .Select-value {
//     color: white !important;
//     background-color: #66c050 !important;
//     border: 1px solid gray !important;
//     margin-left: 0.05rem !important;
//     margin-right: 0.05rem !important;
// 		margin-top: 0rem !important;
//   }
//   .Select-value-label {
//     font-size: $m-size;
//     padding: 0.45rem 1rem !important;
//   }
//   .Select-value-icon {
//     border-right: 1px solid gray !important;
//     padding: 0.45rem 1rem !important;
//     &:hover,
// 		&:focus {
// 			background-color: #66c050 !important;
// 			color: gray !important;
// 		}
//   }
// }

// multi-select__control
// multi-select__control--active
.css-vj8t7z,
.css-2o5izw {
  height: 1rem;
}

.css-2o5izw {
  border-color: #66c050 !important;
  box-shadow: 0 0 0 1px #66c050 !important;
}

// multi-select__value
.css-xwjg1b {
  color: white;
  background-color: #66c050 !important;
  // label
  .css-12jo7m5 {
    color: white;
    font-size: $m-size;
  }
  // remove
  .css-1alnv5e:hover {
    color: gray !important;
    background-color: #66c050 !important;
  }
}

// selected option in menu
.css-z5z6cw,
.css-wqgs6e {
  color: white !important;
  background-color: #66c050 !important;
}

//Multi-select
.multi-select {
  .dropdown[aria-expanded='true'] .dropdown-heading {
    border: 2px solid #66c050 !important;
  }
  .dropdown {
    .dropdown-heading {
      border: 1px solid silver !important;
    }
    .dropdown-content {
      .select-panel {
        // select all
        // options
        .select-item,
        .select-list li .select-item {
          background-color: white !important;
          &:active,
          &:focus,
          &:hover {
            background-color: #bee4b4 !important;
          }
        }
      }
    }
  }
}