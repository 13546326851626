.packages-thumbnail {
  display: flex;
  flex-direction: column;
}

.packages-thumbnail-img {
  width: 100%;
  height: 7.5rem;
  object-fit: contain;
  margin-bottom: .5rem;
}

.package-options-table {
  max-width: 100%;
  th {
    text-align: center;
  }
}