.payment-request-modal {
  max-width: 700px !important;
  width: 700px !important;
}

//.payment-request-modal-title{
//  font-size: 24px;
//  color: #6C6C6C;
//}

.payment-request-modal-header {
  font-size: 36px;
  font-weight: bold;
}

//.btn-ac{
//  background-color: #ACACAC !important;
//}

.payment-request-email-body-section {
  position: relative;
  height: 350px;
  background-color: #f2f2f2;
  overflow: scroll;
}

//.top-email-bar{
//  background-color: #505050 !important;
//}

.payment-request-email-message-input {
  height: 100px;
}

span.react-dropdown-select-option {
  background-color: #66c050;
}
