#priceguide__index {
  .modal-header {
    display: block !important;
  }
  .modal-dialog {
    top: 5% !important;
  }
  .fade.in.modal {
    opacity: 1 !important;
  }
  .fade.in.popover {
    opacity: 1 !important;
    font-size: 14px;
    padding: 10px;
  }

  .modal-backdrop.in {
    opacity: 0.5 !important;
  }
}

.price-guide__msi-background {
  background-color: $itemColor;
}

.price-guide__msi-background-light {
  background-color: lighten($color: $itemColor, $amount: 10%);
}

.price-guide__option-background {
  background-color: $optionColor;
}

.price-guide__option-background-light {
  background-color: lighten($color: $optionColor, $amount: 10%);
}

.price-guide__upcharge-background {
  background-color: $upChargeColor;
}

.price-guide__upcharge-background-light {
  background-color: lighten($color: $upChargeColor, $amount: 10%);
}

.price-guide__table-container {
  border: $borderWidth solid darken($color: $itemColor, $amount: 10%);
  border-radius: 0.4rem;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
}

.price-guide__table-header-container {
  background-color: $itemColor;
  border-bottom: $borderWidth solid darken($color: $itemColor, $amount: 10%);
  display: flex;
  flex-direction: row;
  width: 100%;
}

.price-guide__table-header-cell {
  border-right: $borderWidth solid darken($color: $itemColor, $amount: 10%);
  flex-grow: 1;
  font-weight: 700;
  padding: 0.5rem;
  text-align: center;
}

.price-guide__table-header-image-cell {
  @extend .price-guide__table-header-cell;
  flex-grow: 0;
  max-width: 5rem !important;
  min-width: 5rem !important;
  width: 5rem !important;
}

// Measure Sheet Item Row
.price-guide__table-row {
  border-bottom: $borderWidth solid $itemColor;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.price-guide__msi-container {
  background-color: lighten($color: $itemColor, $amount: 10%);
  display: flex;
  flex-direction: column;
  width: 100%;
}

.price-guide__msi-row {
  display: flex;
  flex-direction: row;
  max-height: $rowHeight;
}

.price-guide__table-cell {
  background-color: transparent;
  border-right: $borderWidth solid $itemColor;
  flex-grow: 1;
}

.price-guide__table-image-cell {
  border-right: $borderWidth solid $itemColor;
  height: $rowHeight;
  min-width: 5rem !important;
  overflow: hidden;
  padding: 0.2rem;
  text-align: center;
  width: 5rem !important;
}

.caret-cell {
  flex-grow: 0;
  min-width: 2.7rem !important;
  max-width: 2.7rem !important;
}

// Price Guide Option Row

.price-guide__options-container {
  // border: $borderWidth solid blue;
  background-color: lighten($color: $optionColor, $amount: 10%);
  border-bottom: 5px solid $optionColor;
  overflow-x: scroll;
  overflow-y: hidden;
}

.price-guide__option-header-container {
  background-color: $optionColor;
  display: flex;
  flex-direction: row;
  height: $subHeaderHeight;
}

.price-guide__option-header-cell {
  border-right: $borderWidth solid darken($color: $optionColor, $amount: 10%);
  flex-grow: 1;
  font-weight: 700;
  text-align: center;
}

.price-guide__option-prices-header-cell {
  border-right: 0;
  font-size: 1rem;
  text-align: center;
  // border: 1px solid green;
}

.price-guide_option-header-office-name-container {
  display: flex;
  flex-direction: row;
}

.price-guide__option-prices-header-office-cell {
  border-right: $borderWidth solid darken($color: $optionColor, $amount: 10%);
  font-size: 1rem;
  font-weight: 700;
  height: $subHeaderHeight;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
  width: 10rem;
}

.price-guide__option-row-parent-container {
  background-color: lighten($color: $optionColor, $amount: 10%);
  display: flex;
  flex-direction: column;
}

.price-guide__option-row-container {
  // border: 1px solid orange;
  border-bottom: $borderWidth solid $optionColor;
  display: flex;
  flex-direction: row;
  // background-color: yellow;
}

.price-guide__option-row-cell {
  border-right: $borderWidth solid $optionColor;
  flex-grow: 1;
  // min-width: 22rem;
  // border: $borderWidth solid yellow;
}

.price-guide__option-prices-container {
  display: flex;
  flex-direction: row;
  // border: $borderWidth solid red;
}

.price-guide__option-price-cell {
  min-width: 10rem;
  max-width: 10rem;
}

// Inputs

// UpCharges

.price-guide__upcharge-parent-container {
  background-color: lighten($color: $upChargeColor, $amount: 10%);
  border-bottom: 5px solid $upChargeColor;
  overflow-x: scroll;
  overflow-y: hidden;
}

.price-guide__upcharge-header-container {
  @extend .price-guide__option-header-container;
  background-color: $upChargeColor;
}

.price-guide__up-charge-header-cell {
  background-color: $upChargeColor;
  border-right: $borderWidth solid darken($color: $upChargeColor, $amount: 10%);
  flex-grow: 1;
  font-weight: 700;
  text-align: center;
}

.price-guide__upcharge-header-image-cell {
  @extend .price-guide__up-charge-header-cell;
  flex-grow: 0;
  max-width: 5rem;
  min-width: 5rem;
}

.price-guide__upcharge-prices-header-cell {
  @extend .price-guide__option-prices-header-cell;
  border-right: 0;
}

.price-guide_upcharge-header-office-name-container {
  @extend .price-guide_option-header-office-name-container;
  // border-right: $borderWidth solid darken($color: $upChargeColor, $amount: 10%);
}

.price-guide__upcharge-prices-header-office-cell {
  @extend .price-guide__option-prices-header-office-cell;
  border-right: $borderWidth solid darken($color: $upChargeColor, $amount: 10%);
  max-width: 200px;
  min-width: 200px;
  height: 2rem;
  width: 100%;
}

.price-guide__upcharge-row-container {
  @extend .price-guide__option-row-container;
  background-color: lighten($color: $upChargeColor, $amount: 10%);
  border-bottom: $borderWidth solid $upChargeColor;
}

.price-guide__upcharge-row-cell {
  border-right: $borderWidth solid darken($color: $upChargeColor, $amount: 10%);
  flex-grow: 1;
}

.price-guide__upcharge-image-cell {
  @extend .price-guide__table-image-cell;
  border-right: 1px solid $upChargeColor;
}

// Edit Modal

.price-guide__edit-msi-modal {
  max-width: 800px !important;
  min-width: 600px !important;
}

.price-guide__edit-msi-modal-additional-details {
  width: 95% !important;
  max-width: unset !important;
  .modal-content {
    overflow: visible;
  }
  .modal-body {
    overflow: visible;
  }

  .leap-select__menu {
    position: relative;
    z-index: 9999999;
  }

  & .lpstrp-select {
    font-size: 12px;
  }
  & input.form-control {
    height: 38px;
  }
  & .lpstrp-select__input {
    input {
      height: unset;
    }
  }
  & .lpstrp-select__value-container {
    padding-bottom: 0px;
    padding-top: 0px;
    div {
      padding-bottom: 0px;
      padding-top: 0px;
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }

  & .lpstrp-select__indicator {
    padding-bottom: 0px;
    padding-top: 0px;
  }

  & .add-detail-block {
    border: 1px solid black;
    border-radius: 4;
    margin-bottom: 8px;
    background-color: white;
  }
}

// Add Buttons

.price-guide__add-option-button {
  background: $optionColor;
  border: 0;
  border-radius: 4px;
  min-height: 36px;
  margin-bottom: 5px;
  // padding: 5px;
  width: 100%;
}

.price-guide__add-upcharge-button {
  background: $upChargeColor;
  border: 0;
  border-radius: 4px;
  min-height: 36px;
  margin-bottom: 5px;
  // padding: 5px;
  width: 100%;
}

.thumnail-pop-up {
  position: absolute;
  left: 60px;
  height: 200px;
  width: 200px;
}
