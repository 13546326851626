.mass_price_change__input-pair {
  margin-top: .5rem;
  > label {
    font-weight: 900;
  }
}

.mass_price_change__footer {
  margin: .5rem;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.mass_price_change__include-up-charge-input-pair {
  display: flex;
  align-items: center;
  label {
    margin-bottom: unset;
    margin-right: .5rem;
  }
}