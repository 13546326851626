.resources-container {
  align-items: stretch;
  display: flex;
  flex-basis: 20rem;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: .5rem;
}

.resources-cell {
  width: 200px;
  margin: auto;
  margin-bottom: 10px;
}

.resources-cell i {
  color:white;
}

.thumbnail .image {
  height: 120px;
  overflow: hidden;
}

.caption {
  background: #F7F7F7;
  font-weight: 600;
  padding: 9px 5px;
  text-align: center;
}

.caption p {
  margin-bottom: 5px;
}

.caption-with-author {
  background: #F7F7F7;
  font-weight: 600;
  padding: 0 5px;
}

.caption-author {
  color:grey;
}

/*.thumbnail {*/

/*height: 190px;*/

/*overflow: hidden; }*/

.view {
  overflow: hidden;
  position: relative;
  text-align: center;
  box-shadow: 1px 1px 2px #e6e6e6;
  cursor: default;
}

.view .mask,
.view .content {
  position: absolute;
  width: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
}

.view img {
  display: block;
  position: relative;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.view .tools {
  text-transform: uppercase;
  color: #fff !important;
  text-align: center;
  position: relative;
  font-size: 17px;
  padding: 3px;
  background: rgba(0, 0, 0, 0.35);
  margin: 43px 0 0 0;
}

.mask .no-caption .tools {
  margin: 90px 0 0 0;
}

.view .tools a {
  display: inline-block;
  color: #FFF;
  font-size: 18px;
  font-weight: 400;
  padding: 0 4px;
}

.view p {
  font-family: Georgia, serif;
  font-style: italic;
  font-size: 12px;
  position: relative;
  color: #fff;
  padding: 10px 20px 20px;
  text-align: center;
}

.view a.info {
  display: inline-block;
  text-decoration: none;
  padding: 7px 14px;
  background: #000;
  color: #fff;
  text-transform: uppercase;
  box-shadow: 0 0 1px #000;
}

.view-first img {
  transition: all 0.2s linear;
}

.view-first .mask {
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.4s ease-in-out;
}

.view-first .tools {
  transform: translateY(-100px);
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.view-first p {
  transform: translateY(100px);
  opacity: 0;
  transition: all 0.2s linear;
}

.view-first:hover img {
  transform: scale(1.1);
}

.view-first:hover .mask {
  opacity: 1;
}

.view-first:hover .tools,
.view-first:hover p {
  opacity: 1;
  transform: translateY(0px);
}

.view-first:hover p {
  transition-delay: 0.1s;
}

// #editButton:hover, #deleteButton:hover, #viewButton:hover {
//   color: #8CC63f;
// }
.tools button {
  background: none;
  box-shadow: none;
  border: none;
  margin: 0;
  padding: 0 .5rem;
  padding-bottom: .5rem;
}