// Colors
$dark-grey: #333333;
$blue: #1c88bf;
$grey: #666;
$medium-grey: #EDEDED;
$light-grey: #808080;
$dark-blue: #364051;
$off-white: #F7F7F7;
$highlight: #27C07D;
// Font Size
$font-size-large: 1.8rem;
$font-size-small: 1.4rem;
// Spacing
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$desktop-breakpoint: 45rem;

$header-height: 5.6rem;
$main-menu-width: 23rem;

// Templates
$appContainerWidth: 768px;
$appContainerHeight: 1024px;

// Price Guide
$rowHeight: 3.6rem;
$borderColor: #ddd;
$borderWidth: .1rem;
$headerColor: #bcbcbc;
$optionHeaderHeight: 2rem;
$subHeaderHeight: 2rem;
$itemColor: lightblue;
$optionColor: lightgreen;
$upChargeColor: lightpink;
$itemHeaderColor: darken($itemColor, 10%);
$optionHeaderColor: darken($optionColor, 10%);
$upChargeHeaderColor: darken($upChargeColor, 10%);
$msiBorderColor: darken($itemColor, 20%);
$optionBorderColor:  darken($optionColor, 20%);
$upChargeBorderColor:  darken($upChargeColor, 20%);