.priceguide__msi-table {
  display: table;
  position: relative;
  height: 100%;
  overflow: hidden;
  width: fit-content;
  z-index: 10;

  & .bp3-collapse {
    & .bp3-collapse-body {
      transform: unset !important;
    }
  }
}

.priceguide__msi_header-container {
  position: relative;
  background-color: $itemHeaderColor;
  border-bottom: 1px solid $msiBorderColor;
  border-top: 1px solid $msiBorderColor;
  display: flex;
  z-index: 5;
}

.priceguide__msi_header-cell {
  border-right: 1px solid $msiBorderColor;
  flex: none;
  font-weight: 600;
  margin: 0;
  overflow: hidden;
  background-color: #5fb3ce;
  padding: 5px;
  resize: horizontal;
  text-align: center;
  width: 200px;
  &_hidden {
    display: none;
  }
  &.static {
    width: 35px;
    resize: none;
  }
  &.thumbnail-header-cell {
    width: 100px;
    resize: none;
  }
}

.priceguide__msi-row {
  position: relative;
  background-color: $itemColor;
  display: flex;
  max-height: 35px;
}

.can-drag {
  & .priceguide__msi-row {
    max-width: 100vw;
  }
}

.priceguide__msi-cell {
  align-items: center;
  border-bottom: 1px solid $msiBorderColor;
  border-right: 1px solid $msiBorderColor;
  background-color: lightblue;
  display: flex;
  flex: none;
  flex-direction: column;
  text-align: center;
  input[type='text'] {
    background-color: transparent;
    border: 0;
    width: 100%;
  }
  input[type='checkbox'] {
    margin: auto;
  }
  &_hidden {
    display: none;
  }
  .multi-select {
    text-align: left;
    width: 100%;
  }
  .bp3-popover-wrapper {
    margin: auto;
  }
}
