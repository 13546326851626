.results-container {
    display: flex;
    flex-direction: row;
}

.results-header_column__action-column {
    width: 2rem;
}

.results-table-tab-container {
    margin-bottom: 1rem;
    width: 100%;
}