.multi-select {
  color: black;
}

.multi-select span {
  color: black !important;
}
.drop-down {
  color: black;
}

.dropdown-heading {
  color: black;
}

.dropdown-heading-value {
  color: black;
}

.up {
  position: absolute;
  top: 0 !important;
  opacity: 0 !important;
  background-color: white !important;
}

.float-line {
  top: 0;
  position: absolute;
  opacity: 1;
  transition: all 1s;
}

.action-icons-section {
  margin-bottom: 40px;
  & > * {
    margin-bottom: 40px;
  }
  i {
    color: black;
    text-decoration: none !important;
  }

  span:hover {
    text-decoration: underline;
  }
}

.estimate-action-link {
  text-decoration: none !important;
  cursor: pointer;
}

.credit-app-source-img {
  max-width: 100%;
  max-height: 75px;
  margin-bottom: 12px;
}

.credit-app-note {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word;
  font-family: poppins;
}
