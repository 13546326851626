.priceguide__option_header-container {
  position: relative;
  background-color: $optionHeaderColor;
  border-bottom: $optionBorderColor;
  border-top: $optionBorderColor;
  display: flex;
  min-height: 2rem;
  .priceguide__option_header-cell {
    border-right: 1px solid $optionBorderColor;
  }
}

.priceguide__option_header-cell {
  border-right: $optionBorderColor;
  font-weight: 600;
  margin: 0;
  overflow-x: scroll;
  resize: horizontal;
  text-align: center;
  width: 200px;
  button {
    background-color: transparent;
    border: 0;
  }
  &_hidden {
    display: none;
  }
  &.static {
    min-width: 3.5rem;
    max-width: 3.5rem;
    overflow: hidden;
    resize: none;
  }
}

.priceguide__option-row {
  background-color: $optionColor;
  display: flex;
  max-height: 35px;
}

.can-drag {
  & .priceguide__option-row {
    max-width: 100vw;
  }
}

.priceguide__option-cell {
  align-items: center;
  border-bottom: 1px solid $optionBorderColor;
  border-right: 1px solid $optionBorderColor;
  background-color: $optionColor;
  display: flex;
  flex: none;
  flex-direction: column;
  position: relative;
  text-align: center;
  input {
    background-color: transparent;
    border: 0;
    width: 100%;
  }
  input[type='checkbox'] {
    margin: auto;
    width: unset;
  }
  &_hidden {
    display: none;
  }
}
