.credit-apps__page {
  background-color: white;
  height: fit-content;
  min-height: 792px;
  max-width: 612px;
  min-width: 612px;
  padding: 20px;
  position: relative;
}

.credit-apps__header-container {
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
}

.credit-apps__company-logo-container {
  position: relative;
  width: 100%;
}

.credit-apps__company-logo {
  height: 50px;
  object-fit: contain;
}

.credit-apps__title {
  font-size: 14px;
  font-weight: bold;
  margin-top: 2px;
  text-transform: uppercase;
}

.credit-apps__date {
  bottom: 0;
  font-size: 9px;
  position: absolute;
  right: 0;
}

.credit-apps__section {
  font-size: 10px;
  font-weight: bold;
  width: 50%;
}

.credit-apps__section-middle-container {
  border-bottom: 1px solid black;
  display: flex;
  margin-top: 2px;
  padding-bottom: 2px;
  padding-top: 2px;
}

.credit-apps__section-title {
  font-size: 12px;
  font-weight: bold;
  width: 50%;
}

.credit-apps__disclosure {
  font-size: 6px;
  margin-top: 4px;
  margin-bottom: 20px;
  font-family: inherit;
}

.credit-apps__section-signature-container {
  bottom: 0;
  display: flex;
  justify-content: space-around;
  left: 0;
  padding: 0 20px 10px 20px;
  position: absolute;
  right: 0;
}

.credit-apps__signature {
  height: 40px;
  position: relative;
  width: 200px;
}

.credit-apps__signature-name {
  border-top: 1px solid black;
  bottom: 0;
  position: absolute;
  font-size: 10px;
  font-weight: bold;
  padding-left: 10px;
  width: 100%;
}

.credit-app-canvas {
  display: flex;
  overflow: scroll;
  height: 100%;
  justify-content: center;
  padding-top: 100px;
}

.remove-thumbnail {
  color: red;
  position: absolute;
  z-index: 100;
  right: 0;
  font-weight: 700;
  top: 0;
}
